
import * as CONST from '../Constants.js';
import '../App.css';
//import 'katex/dist/katex.min.css'

import formel1 from '../formel1.png'
import formel2 from '../formel2.png'
import formel3 from '../formel3.png'
import formel4 from '../formel4.png'
import formel5 from '../formel5.png'
import formel6 from '../formel6.png'
import formel7 from '../formel7.png'
import formel8 from '../formel8.png'
import formel8e from '../formel8e.png'
import formel9 from '../formel9.png'
import formel9e from '../formel9e.png'
import formel1e from '../formel1e.png'
import formel2e from '../formel2e.png'
import formel5e from '../formel5e.png'

export default function HinweiseScreen({
  language, screen, setScreen,
}) {

  return (
    <div style={{
      display: 'flex',


      flexDirection: 'column',
      marginTop: CONST.MARGINGROSS,
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'Inter', fontSize: CONST.SCHRIFTGROSS,
      color: 'white',
    }}>
      <div style={{ width: '80%', color: 'white', alignItems: 'center' }}>


        <h2 >
          {language ? 'About CADDy' : 'Über CADDy'}
        </h2>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
          <p>
            {language ? '???' : 'Folgt...'}
          </p>

        </div>

      </div>



    </div>
  )
}