import React, { useState, useEffect } from 'react';
import Auswahl from '../components/Auswahl.js';
import AuswahlVertikal from '../components/Auswahl_Vertikal.js';
import Eingabe from '../components/Eingabe.js';
import Ergebnis from '../components/Ergebnis.js';
import * as CONST from '../Constants.js';

export default function VerfahrenScreen({
    language, setScreen,
    gender, setGender, groesse, setGroesse, gewicht, setGewicht,
    E_dosiergewicht, krea,
    unitGroesse, setUnitGroesse, filtrationsleistung, setFiltrationsleistung,
    vancospiegel, setVancospiegel,
    alter, setAlter, serumkreatinin,
    setSerumkreatinin, eGFR, setEGFR, MHK, setMHK,
    medikation, setMedikation,
    verfahren, setVerfahren,
    resetAll }) {



    const computeEGFR = () => {
        let result = null
        console.debug('krea innen', krea)
        console.debug('dosiergewicht innen', E_dosiergewicht)

        let _serumkreatinin = language ? (Math.min(serumkreatinin, gender ? 0.6 : 0.7) / 88.4)
            : Math.min(serumkreatinin, gender ? 0.6 : 0.7)

        if (gender == 0) {
            result = (((140 - alter) * E_dosiergewicht) / (72 * krea))              //Männlich
        } else {
            result = ((((140 - alter) * E_dosiergewicht) / (72 * krea)) * 0.85)
            console.debug('result', result)
        }
        result = (Math.round(result * 100) / 100).toFixed(0);
        return result
    }

    const computedegfr = serumkreatinin? computeEGFR(): eGFR

    useEffect(() => {
        setEGFR(computedegfr)
    }, [computedegfr])

    console.debug('computedegfr', computedegfr)

   
    return (<div style={{
        display: 'flex',
        flexDirection: 'column',
        width: CONST.SCREENWIDTH,
        marginTop: CONST.MARGINGROSS,
        fontFamily: 'Inter', fontSize: CONST.SCHRIFTGROSS,
        color: 'white',
    }}>

        <div style={{ display: 'flex', flexDirection: 'column',  }} onClick={()=>  setScreen(2)}>
            <AuswahlVertikal  titel={''} col={true} optionArray={['Kontinuierliche Dialyse', 'Kontinuierliche Hämodiafiltration', 'Intermittierende Dialyse', 'SLED Dialyse', 'ADVOS Dialyse']}
                setVariable={setVerfahren} variable={verfahren}></AuswahlVertikal>
        </div>

       {/*  <div style={{ display: 'flex', width: verfahren != null? '100%' : '56.5%', flexDirection: 'row', alignSelf:'flex-start', justifyContent: 'space-evenly' }}>
            <div
                className='resetbutton'
                onClick={resetAll}>
                Reset
            </div>

            {verfahren != null &&
                <div
                    className='weiterbutton'

                    onClick={() => {
                        setScreen(2)
                    }}> {language ? 'Next' : 'Weiter'} </div>}
        </div> */}
    </div>
    )
}