import React, { useState } from 'react';
import Auswahl from '../components/Auswahl.js';
import Eingabe from '../components/Eingabe.js';
import InfoDrei from '../components/InfoDrei.js';
import InfoTabelle from '../components/InfoTabelle.js';
import InfoParameter from '../components/InfoParameter.js';
import * as CONST from '../Constants.js';
import VerfahrenScreen from './1_VerfahrenScreen.js';
import { useEffect } from 'react';

/* function customRound(value) {
    if (value >= 10000) {
        return Math.round(value / 1000) * 1000;
    } else if (value >= 1000) {
        return Math.round(value / 100) * 100;
    } else if (value >= 100) {
        return Math.round(value / 10) * 10;
    } else if (value >= 10) {
        return Math.round(value);
    } else if (value >= 1) {
        return Math.round(value * 10) / 10;
    } else if (value >= 0.1) {
        return Math.round(value * 100) / 100;
    } else if (value >= 0.01) {
        return Math.round(value * 1000) / 1000;
    } else if (value >= 0.001) {
        return Math.round(value * 10000) / 10000;
    } else {
        return Math.round(value);
    }
} */

export default function EinstellungenScreen({
    language, screen, setScreen,
    einstellungsliste, setEinstellungsliste,
    verfahren
}) {
    const [titel2, setTitel2] = useState(language ? 'Dose' : 'Dosis')
    const [titel3, setTitel3] = useState(language ? 'Zusatzdosis an Dialysetagen' : 'Zusatzdosis an Dialysetagen')

/*     useEffect(() => {
        if (verfahren == 'Kontinuierliche Dialyse') {
            setTitel2(language ? 'Dose with dialysis ???' : 'Dosis mit Dialyse')
            setTitel3(language ? 'Dose without dialysis ???' : 'Dosis ohne Dialyse')
        } else if (verfahren == 'Kontinuierliche Hämodiafiltration') {
            setTitel2(language ? 'Dose with filtration ???' : 'Dosis mit Filtration')
            setTitel3(language ? 'Dose without filtration ???' : 'Dosis ohne Filtration')
        } else if (verfahren == 'Intermittierende Dialyse') {
            setTitel2(language ? 'Dose' : 'Dosis')
            setTitel3(language ? 'Zusatzdosis an Dialysetagen' : 'Zusatzdosis an Dialysetagen')
        } else if (verfahren == 'SLED Dialyse') {
            setTitel2(language ? 'Dose' : 'Dosis')
            setTitel3(language ? 'Zusatzdosis an Dialysetagen' : 'Zusatzdosis an Dialysetagen')
        } else if (verfahren == 'ADVOS Dialyse') {
            setTitel2(language ? 'Dose with dialysis ???' : 'Dosis mit Dialyse')
            setTitel3(language ? 'Dose without dialysis ???' : 'Dosis ohne Dialyse')
        }

    }, [verfahren]) */

    console.log('einstellungsliste', einstellungsliste)
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: CONST.SCREENWIDTH,
            marginTop: CONST.MARGINGROSS,
            fontFamily: 'Inter', fontSize: CONST.SCHRIFTGROSS,
            color: 'white',
        }}>

            {einstellungsliste.map((einstellung, index) => {
                const { medikament, basisdosis, dosis, zusatzdosis, restnierenfunktion, dialysatfluss, dialysedauer, blutfluss, verfahren } = einstellung
                let titel2 = ''
                let titel3 = ''

                switch (verfahren) {
                    case 'Kontinuierliche Dialyse':
                        titel2 = language ? 'Dose with dialysis ???' : 'Dosis mit Dialyse'
                        titel3 = language ? 'Dose without dialysis ???' : 'Dosis ohne Dialyse'
                        break;
                    case 'Kontinuierliche Hämodiafiltration':
                        titel2 = language ? 'Dose with filtration ???' : 'Dosis mit Filtration'
                        titel3 = language ? 'Dose without filtration ???' : 'Dosis ohne Filtration'
                        break;
                    case 'Intermittierende Dialyse':
                        titel2 = language ? 'Dose' : 'Dosis'
                        titel3 = language ? 'Zusatzdosis an Dialysetagen' : 'Zusatzdosis an Dialysetagen'
                        break;
                    case 'SLED Dialyse':
                        titel2 = language ? 'Dose' : 'Dosis'
                        titel3 = language ? 'Zusatzdosis an Dialysetagen' : 'Zusatzdosis an Dialysetagen'
                        break;
                    case 'ADVOS Dialyse':
                        titel2 = language ? 'Dose with dialysis ???' : 'Dosis mit Dialyse'
                        titel3 = language ? 'Dose without dialysis ???' : 'Dosis ohne Dialyse'
                        break;
                    default:
                        break;
                }        

                return <>
                    <InfoDrei
                        titel={language ? '???' : 'Dosierungsempfehlung'}
                        infos={[language ? 'Drug' : 'Arzneistoff', titel2, titel3]}
                        ergebnisse={[medikament, dosis, zusatzdosis]}
                        units={['', 'mg/24h', 'mg']} />



                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: CONST.MARGINGROSS }}>
                        <label style={{ color: 'white', fontFamily: 'Inter', fontSize: CONST.SCHRIFTMITTEL, marginTop: CONST.MARGINKLEIN, textAlign: 'left', }}>
                            {language ? '???' : 'Einstellungen'}
                        </label>
                        {verfahren == 'Kontinuierliche Dialyse' &&
                            <label style={{ color: 'white', fontFamily: 'Inter', fontSize: CONST.SCHRIFTMITTEL, marginTop: CONST.MARGINKLEIN, textAlign: 'left', }}>
                                {language ? '???' : 'Verfahren: '} {verfahren}
                                <br />  {language ? '???' : 'Restnierenfunktion (ml/min): '} {restnierenfunktion}
                                <br />  {language ? '???' : 'Dialysatfluss (l/h): '} {dialysatfluss}
                            </label>}
                        {verfahren == 'Kontinuierliche Hämodiafiltration' &&
                            <label style={{ color: 'white', fontFamily: 'Inter', fontSize: CONST.SCHRIFTMITTEL, marginTop: CONST.MARGINKLEIN, textAlign: 'left', }}>
                                {language ? '???' : 'Verfahren: '} {verfahren}
                                <br />  {language ? '???' : 'Restnierenfunktion (ml/min): '} {restnierenfunktion}
                                <br />  {language ? '???' : 'Filtrationsrate (l/h): '} {dialysatfluss}
                            </label>}
                        {(verfahren == 'Intermittierende Dialyse' || verfahren == 'SLED Dialyse') &&
                            <label style={{ color: 'white', fontFamily: 'Inter', fontSize: CONST.SCHRIFTMITTEL, marginTop: CONST.MARGINKLEIN, textAlign: 'left', }}>
                                {language ? '???' : 'Verfahren: '} {verfahren}
                                <br />  {language ? '???' : 'Restnierenfunktion (ml/min): '} {restnierenfunktion}
                                <br />  {language ? '???' : 'Dialysatfluss (l/h): '} {dialysatfluss}
                                <br />  {language ? '???' : 'Blutfluss (l/h): '} {blutfluss}
                                <br />  {language ? '???' : 'Dialysedauer (h): '} {dialysedauer}
                            </label>}
                        {verfahren == 'ADVOS Dialyse' &&
                            <label style={{ color: 'white', fontFamily: 'Inter', fontSize: CONST.SCHRIFTMITTEL, marginTop: CONST.MARGINKLEIN, textAlign: 'left', }}>
                                {language ? '???' : 'Verfahren: '} {verfahren}
                                <br />  {language ? '???' : 'Restnierenfunktion (ml/min): '} {restnierenfunktion}
                                <br />  {language ? '???' : 'Blutfluss (l/h): '} {blutfluss}
                            </label>}
                        <label style={{ color: 'white', fontFamily: 'Inter', fontSize: CONST.SCHRIFTMITTEL, marginTop: CONST.MARGINKLEIN, textAlign: 'left', }}>
                            {language ? '???' : 'Dosis (mg/24h): '} {basisdosis}
                        </label>

                    </div>
                </>
            })}

{einstellungsliste && einstellungsliste.length > 0 &&
            <div
                className='weiterbutton'
                style={{ display: 'flex', }}
                onClick={() => {
                    setEinstellungsliste([])
                }}> Reset </div> }



        </div>
    )
}