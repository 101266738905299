import { useState, useEffect, useMemo } from 'react';
import Auswahl from '../components/Auswahl.js';
import Eingabe from '../components/Eingabe.js';
import InfoDrei from '../components/InfoDrei.js';
import InfoTabelle from '../components/InfoTabelle.js';
import * as CONST from '../Constants.js';
import Dropdown from '../components/Dropdown.js';
import Papa from 'papaparse';


function customRound(value) {
    if (value >= 10000) {
        return Math.round(value / 1000) * 1000;
    } else if (value >= 1000) {
        return Math.round(value / 100) * 100;
    } else if (value >= 100) {
        return Math.round(value / 10) * 10;
    } else if (value >= 10) {
        return Math.round(value);
    } else if (value >= 1) {
        return Math.round(value * 10) / 10;
    } else if (value >= 0.1) {
        return Math.round(value * 100) / 100;
    } else if (value >= 0.01) {
        return Math.round(value * 1000) / 1000;
    } else if (value >= 0.001) {
        return Math.round(value * 10000) / 10000;
    } else {
        return Math.round(value);
    }
}


export default function ADVOSScreen({
    language, setScreen,
    verfahren,
    setUnitGroesse, einstellungsliste ,
    setEinstellungsliste,
    restnierenfunktion, setRestnierenfunktion,
    dialysatfluss, setDialysatFluss,
    blutfluss, setBlutfluss,
    dialysedauer, setDialysedauer,
}) {
    const [lokalEinstellungsliste, setLokaleEinstellungsliste] = useState([]);
    const [berechnung, setBerechnung] = useState(false)
    const [medikament, setMedikament] = useState(null)
    const [dosen, setDosen] = useState(null)
    const [dosis, setDosis] = useState(null)
    const [hinweis, setHinweis] = useState('')

    const [fullTable, setFullTable] = useState([]);
    const [options, setOptions] = useState([]);
    const columnName = 'Medikament';
    const csvFilePath = '/data.csv';


    useEffect(() => {
        // Function to fetch and parse the CSV file
        const fetchCsvData = async () => {
            const response = await fetch(csvFilePath);
            const reader = response.body.getReader();
            const result = await reader.read();
            const decoder = new TextDecoder('utf-8');
            const csv = decoder.decode(result.value);
            Papa.parse(csv, {
                header: true,
                complete: (result) => {
                    const headers = result.meta.fields;

                    const options = result.data.map(row => ({
                        value: row[columnName],
                        label: row[columnName]
                    }));
                    const dosen = result.data.map(row => ({
                        medikament: row[columnName],
                        Vd: row[headers[1]],
                        HWZ: row[headers[2]],
                        nichtrenalAnteil: row[headers[3]],
                        bioverfuegbarkeit: row[headers[5]],
                        salzfaktor: row[headers[6]],
                        dosis: row[headers[7]],
                        proteinbindung: row[headers[4]],
                        hinweis: row[headers[8]]
                    }));

                    setOptions(options);
                    setDosen(dosen);
                    //alert(JSON.stringify(dosen))
                },
            });
        };

        fetchCsvData();
    }, [csvFilePath, columnName]);

    useEffect(() => {
        if (medikament !== null && dosen !== null) {
            const selectedMedikament = dosen.find(d => d.medikament === medikament.value);

            // Funktion zur sicheren Umwandlung von Strings in Float-Werte
            const toFloat = (str) => parseFloat(str.replace(',', '.'));

            const Vd = selectedMedikament ? toFloat(selectedMedikament.Vd) : 0;
            const HWZ = selectedMedikament ? toFloat(selectedMedikament.HWZ) : 0;
            const nichtrenalAnteil = selectedMedikament ? toFloat(selectedMedikament.nichtrenalAnteil) : 0;
            const bioverfuegbarkeit = selectedMedikament ? toFloat(selectedMedikament.bioverfuegbarkeit) : 0;
            const salzfaktor = selectedMedikament ? toFloat(selectedMedikament.salzfaktor) : 0;
            const proteinbindung = selectedMedikament ? toFloat(selectedMedikament.proteinbindung) : 0;
            const basisdosis = selectedMedikament ? toFloat(selectedMedikament.dosis) : 0;
            if (berechnung) {
                setDosis(basisdosis)
            }
            const hinweis = selectedMedikament ? selectedMedikament.hinweis : '';
            setHinweis(hinweis)

            let blutflussfaktor = blutfluss / (parseFloat(blutfluss) + parseFloat(dialysatfluss));
            let Clearance = (0.6931 * Vd * 75) / HWZ;
            let clearancePatient = (nichtrenalAnteil + (restnierenfunktion/100) * (1 - nichtrenalAnteil)) * Clearance;
            let clearanceADVOS = blutfluss*0.06/2*(1- proteinbindung)*0.9 + (proteinbindung*1);
            let summeCl = clearancePatient + clearanceADVOS;
            let dosisBioverfuegbar = dosis * bioverfuegbarkeit * salzfaktor;
            let CPSS = dosisBioverfuegbar / (Clearance * 24)
            let Cdialysat = CPSS * (1 - proteinbindung) * blutflussfaktor;
            let dialysatverlust = dialysedauer * dialysatfluss * Cdialysat;
            let dialysatverlustDosis = dialysatverlust * dosis / dosisBioverfuegbar;
            let nichtrenaleEliminationDosis = nichtrenalAnteil * dosis;
            let nichtrenaleEliminationDosisNormal = (1 - nichtrenalAnteil) * dosis;
            let dosisOhneHD = (clearancePatient/Clearance) * dosis;
            let dosismitHD = (summeCl/Clearance) * dosis;

            let zusatzdosis = customRound(dosisOhneHD);


            console.log('blutfluss', blutfluss)
            console.log('dialysatfluss', dialysatfluss)
            console.log('blutflussfaktor', blutflussfaktor)
            console.log('Vd', Vd)
            console.log('HWZ', HWZ)
            console.log('Clearance', Clearance)
            console.log('proteinbindung', proteinbindung)
            console.log('CPSS', CPSS)
            console.log('nichtrenalAnteil', nichtrenalAnteil)
            console.log('bioverfuegbarkeit', bioverfuegbarkeit)
            console.log('salzfaktor', salzfaktor)
            console.log('dosis', dosis)
            console.log('Cdialysat', Cdialysat)
            console.log('dialysatverlust', dialysatverlust)
            console.log('dosisBioverfuegbar', dosisBioverfuegbar)
            console.log('dialysatverlustDosis', dialysatverlustDosis)
            console.log('nichtrenaleEliminationDosis', nichtrenaleEliminationDosis)
            console.log('nichtrenaleEliminationDosisNormal', nichtrenaleEliminationDosisNormal)
            console.log('dosisOhneHD', dosisOhneHD)
            console.log('dosismitHD', dosismitHD)
            console.log('zusatzdosis', zusatzdosis)

            setLokaleEinstellungsliste(prev => {
                return [ {verfahren, medikament: medikament.value,basisdosis:dosis, dosis: customRound(dosismitHD), zusatzdosis: zusatzdosis, restnierenfunktion: restnierenfunktion, dialysatfluss: dialysatfluss, blutfluss: blutfluss, dialysedauer: dialysedauer}]
              /*   if (prev.find(e => e.medikament === medikament.value)) {
                    // replace existing entry
                    return prev.map(e => e.medikament === medikament.value ? { medikament: medikament.value, dosis: customRound(dosismitHD), zusatzdosis: zusatzdosis } : e);
                } else {
                    return [...prev, { medikament: medikament.value, dosis: customRound(dosismitHD), zusatzdosis: zusatzdosis }]
                } */
            }

            );
            setBerechnung(false)

        }
    }, [medikament, dosis, berechnung, dosen, restnierenfunktion, dialysatfluss, blutfluss, dialysedauer]);


    useEffect(() => {
        setScreen(2)

        setRestnierenfunktion('15')
        //setDialysatFluss('30')
        setBlutfluss('100')
        //setDialysedauer('5')
    }, [])

    const [option, setOption] = useState(false);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            fontFamily: 'Inter', fontSize: CONST.SCHRIFTGROSS,
            color: 'white',
        }}>

            <div style={{
                display: 'flex', width: CONST.SCREENWIDTH,
                flexDirection: 'column',

                marginTop: CONST.MARGINGROSS,
            }}>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Eingabe titel={language ? 'Renal function ???' : 'Restnierenfunktion'} klein optionArray={['15']} decimals={1} placeh={true}
                        setVariable={setRestnierenfunktion} variable={restnierenfunktion} unit={['ml/min']} faktor={[1]} />
                    <Eingabe titel={language ? '???' : 'Blutfluss'} klein decimals={1} placeh={true}
                        optionArray={['100']} setVariable={setBlutfluss} variable={blutfluss}
                        setunit={setUnitGroesse} unit={['ml/min']} faktor={[1]} />
                </div>
               

                <Dropdown titel={'Arzneistoff'} options={options} handleSelect={(text) => {
                    setBerechnung(true)
                    setMedikament(text)
                }} />
                <Eingabe titel={language ? 'Dose' : 'Dosierung'} optionArray={[dosis]} placeh={true} gross={true} setOnSubmit={true}
                    setVariable={setDosis} variable={dosis} min={0} max={100000} unit={['mg/24h']} faktor={[1]} decimals={1} />

{hinweis != '' &&
                    <div style={{ display: 'flex', flexDirection: 'column', backgroundColor:'transparent', marginTop: CONST.MARGINKLEIN, padding: 2, fontSize: CONST.SCHRIFTKLEIN }}>
                        {hinweis} 
                    </div>}


                <div style={{ display: 'flex', width: dosis ? '100%' : '50%', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                    <div
                        className='weiterbutton'
                        style={{ display: 'flex', }}
                        onClick={() => {
                            setScreen(0)
                        }}> {language ? 'Back' : 'Zurück'} </div>

                    {dosis &&
                        <div className='weiterbutton'
                            style={option ? { backgroundColor: CONST.GREEN, } : {}}
                            onClick={() => {
                                console.debug('lokalEinstellungsliste', lokalEinstellungsliste)
                                console.debug('einstellungsliste', einstellungsliste)
                                let _einstellungsliste = einstellungsliste //.filter(e => e.medikament !== medikament.value)
                                console.debug('_einstellungsliste', _einstellungsliste)
                                setEinstellungsliste([..._einstellungsliste, ...lokalEinstellungsliste])

                          
                                setScreen(5)
                            }}>  {language ? 'Next' : 'Berechnen'} </div>}
                </div>
            </div>


        </div>
    )
}