import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import * as CONST from '../Constants.js';

const options = [
    { value: 'Acetazolamid', label: 'Acetazolamid i.v.' },
    { value: 'Acetylcystein', label: 'Acetylcystein i.v.' },
    { value: 'Adenosin', label: 'Adenosin i.v.' },
    { value: 'Adrenalin', label: 'Adrenalin i.v.' },
    { value: 'Alprostadil', label: 'Alprostadil i.v.' },
    { value: 'Amikacin', label: 'Amikacin i.v.' },
    { value: 'Amiodaron', label: 'Amiodaron i.v.' },
    { value: 'Amoxicillin', label: 'Amoxicillin i.v.' },
    { value: 'Amphotericin B', label: 'Amphotericin B i.v.' },
    { value: 'Ampicillin', label: 'Ampicillin i.v.' },
    { value: 'Anidulafungin', label: 'Anidulafungin i.v.' },
    { value: 'Argatroban', label: 'Argatroban i.v.' },
    { value: 'Atracurium', label: 'Atracurium i.v.' },
    { value: 'Atropin', label: 'Atropin i.v.' },
    { value: 'Azithromycin', label: 'Azithromycin i.v.' },
    { value: 'Aztreonam', label: 'Aztreonam i.v.' },
    { value: 'Benzylpenicillin', label: 'Benzylpenicillin i.v.' },
    { value: 'Bivalirudin', label: 'Bivalirudin i.v.' },
    { value: 'Caspofungin', label: 'Caspofungin i.v.' },
    { value: 'Cefazolin', label: 'Cefazolin i.v.' },
    { value: 'Cefepime', label: 'Cefepime i.v.' },
    { value: 'Cefotaxim', label: 'Cefotaxim i.v.' },
    { value: 'Cefoxitin', label: 'Cefoxitin i.v.' },
    { value: 'Ceftarolin', label: 'Ceftarolin i.v.' },
    { value: 'Ceftazidim', label: 'Ceftazidim i.v.' },
    { value: 'Ceftriaxon', label: 'Ceftriaxon i.v.' },
    { value: 'Cefuroxim', label: 'Cefuroxim i.v.' },
    { value: 'Ciprofloxacin', label: 'Ciprofloxacin i.v.' },
    { value: 'Clindamycin', label: 'Clindamycin i.v.' },
    { value: 'Cloxacillin', label: 'Cloxacillin i.v.' },
    { value: 'Colistin', label: 'Colistin i.v.' },
    { value: 'Daptomycin', label: 'Daptomycin i.v.' },
    { value: 'Dexmedetomidin', label: 'Dexmedetomidin i.v.' },
    { value: 'Dobutamin', label: 'Dobutamin i.v.' },
    { value: 'Dopamin', label: 'Dopamin i.v.' },
    { value: 'Doripenem', label: 'Doripenem i.v.' },
    { value: 'Doxycyclin', label: 'Doxycyclin i.v.' },
    { value: 'Ertapenem', label: 'Ertapenem i.v.' },
    { value: 'Erythromycin', label: 'Erythromycin i.v.' },
    { value: 'Esmolol', label: 'Esmolol i.v.' },
    { value: 'Famotidin', label: 'Famotidin i.v.' },
    { value: 'Fentanyl', label: 'Fentanyl i.v.' },
    { value: 'Fosfomycin', label: 'Fosfomycin i.v.' },
    { value: 'Fosfomycin', label: 'Fosfomycin i.v.' },
    { value: 'Furosemid', label: 'Furosemid i.v.' },
    { value: 'Gentamicin', label: 'Gentamicin i.v.' },
    { value: 'Haloperidol', label: 'Haloperidol i.v.' },
    { value: 'Heparin', label: 'Heparin i.v.' },
    { value: 'Hydrocortison', label: 'Hydrocortison i.v.' },
    { value: 'Imipenem', label: 'Imipenem i.v.' },
    { value: 'Isoproterenol', label: 'Isoproterenol i.v.' },
    { value: 'Kanamycin', label: 'Kanamycin i.v.' },
    { value: 'Ketamin', label: 'Ketamin i.v.' },
    { value: 'Ketorolac', label: 'Ketorolac i.v.' },
    { value: 'Labetalol', label: 'Labetalol i.v.' },
    { value: 'Levofloxacin', label: 'Levofloxacin i.v.' },
    { value: 'Linezolid', label: 'Linezolid i.v.' },
    { value: 'Lorazepam', label: 'Lorazepam i.v.' },
    { value: 'Meropenem', label: 'Meropenem i.v.' },
    { value: 'Metronidazol', label: 'Metronidazol i.v.' },
    { value: 'Micafungin', label: 'Micafungin i.v.' },
    { value: 'Midazolam', label: 'Midazolam i.v.' },
    { value: 'Moxifloxacin', label: 'Moxifloxacin i.v.' },
    { value: 'Nafcillin', label: 'Nafcillin i.v.' },
    { value: 'Naloxon', label: 'Naloxon i.v.' },
    { value: 'Norepinephrin', label: 'Norepinephrin i.v.' },
    { value: 'Oxacillin', label: 'Oxacillin i.v.' },
    { value: 'Pantoprazol', label: 'Pantoprazol i.v.' },
    { value: 'Piperacillin', label: 'Piperacillin i.v.' },
    { value: 'Polymyxin B', label: 'Polymyxin B i.v.' },
    { value: 'Propofol', label: 'Propofol i.v.' },
    { value: 'Rifampicin', label: 'Rifampicin i.v.' },
    { value: 'Rocuronium', label: 'Rocuronium i.v.' },
    { value: 'Sulfamethoxazol', label: 'Sulfamethoxazol i.v.' },
    { value: 'Sulbactam', label: 'Sulbactam i.v.' },
    { value: 'Tazobactam', label: 'Tazobactam i.v.' },

];

const DropdownWithTextInput = ({ titel, options, handleSelect }) => {
    const [selectedOption, setSelectedOption] = useState(null);

   

    

    const handleChange = (option) => {
        setSelectedOption(option);
        handleSelect(option);
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            display: 'flex',
            height: '28px',
            width: '212px',
            marginLeft: '58px',
            marginTop: '0px',
            borderWidth: 2,
            borderColor: 'white',
            backgroundColor: 'rgba(83, 158, 123, 0.664)',
            borderRadius: 8,
            fontFamily: 'Inter',
            fontSize: CONST.SCHRIFTMITTEL,
            textAlign: 'center',
            color: 'white',
        }),
        input: (provided) => ({
            ...provided,
            color: 'white',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'white',
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'black',
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: 'rgba(83, 158, 123, 0.664)',
            color: 'white',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'rgba(122, 122, 122, 0.333)' : 'rgba(83, 158, 123, 0.664)',
            color: 'white',
        }),
    };


    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            margin: CONST.MARGINKLEIN,
            backgroundColor: '#5555',
            height: CONST.BOXHEIGHT,
            borderRadius: 8,
            width: CONST.BOXWIDTH,
            justifyContent: 'center',
        }}>

            <CreatableSelect
                styles={customStyles}
                id="dropdown"
                options={options}
                value={selectedOption}
                onChange={handleChange}
                placeholder="---"
            />
            {/* {selectedOption && <p>You selected: {selectedOption.label}</p>} */}
            <label style={{ fontFamily: 'Inter', fontSize: CONST.SCHRIFTMITTEL, marginTop: CONST.MARGINKLEIN, height: '16px' }}>
                {titel}
            </label>
        </div>
    );
};

export default DropdownWithTextInput;
